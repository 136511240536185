import { registerStart, registerSuccess, registerFailure } from '../redux/slices/register_slice';
import apiRepository from "../../data/repository";
import RegisterUserUseCase from '../../domain/usecases/get_register_usecase';

export const registerUserEvent = (first_name: string, last_name: string, email: string, phone: string, business_rut: string, business_name: string, business_category: string) => async (dispatch) => {

    const usecase = new RegisterUserUseCase(apiRepository);

    dispatch(registerStart());

    const result = await usecase.execute({
        user_data: { first_name, last_name, email },
        company_data: { phone, business_rut, business_name, business_category }
    });


    result.fold(
        (error) => {
            dispatch(registerFailure(error.message));
        },
        () => {
            dispatch(registerSuccess());
        }
    );
};
