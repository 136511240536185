import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import AppImages from "../../theme/app_images";

const SuccessRegisterPage = () => {

    return (
        <Box
            sx={{
                height: '80vh',
                backgroundImage: `url(${AppImages.successRegister})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Grid container padding={10}>
                <Grid item xs>
                    <Typography variant="h4" color={'white'} fontWeight={600}>
                        ¡Gracias por asociarte!
                    </Typography>
                    <Typography variant="h4" color={'white'} fontWeight={600}>
                        Pronto nos contactaremos contigo.
                    </Typography>
                </Grid>
            </Grid>
        </Box>);
};

export default SuccessRegisterPage;