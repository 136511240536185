export enum TransactionStatus {
    PENDING = 0,
    CONFIRMED = 1,
}

export interface TransactionEntity {
    id: string;
    file_url: string;
    payment_id: string;
    status: TransactionStatus;
    created_at: string;
    updated_at: string;
}

export function mapJsonToTransactionEntity(json): TransactionEntity {
    return {
        id: json.id,
        file_url: json.file,
        payment_id: json.payment_id,
        status: Object.values(TransactionStatus)[json.status],
        created_at: json.created_at,
        updated_at: json.updated_at,
    } as TransactionEntity;
}