export interface ProductEntity {
    id: string;
    name: string;
    price: number;
    description: string;
    imageUrl: string;
    category: CategoryProductEnum;
    createdAt: string;
    updatedAt: string;
}

export enum CategoryProductEnum {
    KITCHEN = "KITCHEN",
    CONTRUCTION = "CONTRUCTION",
    BATHROOM = "BATHROOM",
    ELECTRONIC = "ELECTRONIC",
    CLOTHING = "CLOTHING",
    FURNITURE = "FURNITURE",
    OTHER = "OTHER"
}

export function mapJsonToProductEntity(json): ProductEntity {
    return {
        id: json.id,
        name: json.name,
        price: json.price,
        description: json.description,
        imageUrl: json.image_url,
        category: Object.values(CategoryProductEnum)[json.category],
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as ProductEntity;
}
