import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({

    palette: {
        primary: {
            main: '#0A66A5',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: 'none',
            fontSize: '15px',
            fontWeight: 500,
            variants: [
                {
                    props: { variant: 'text' },
                    style: {
                        fontWeight: 800,
                    },
                },
            ],
        },
    }
});


export default theme;