// src/components/ContainerList.tsx
import React, { useEffect } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { ContainerFullDetailEntity } from '../../domain/entities';

import { NumberInput } from './number_input';
import { useNavigate } from 'react-router-dom';

interface ContainerQuantityTableProps {
    container: ContainerFullDetailEntity;
    lenghtPrices: number;
}

const CLPFormatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
});

interface PriceInterval {
    from: number;
    to: number;
    price: number;
}

const ContainerQuantityTable: React.FC<ContainerQuantityTableProps> = ({ container, lenghtPrices }) => {
    const navigate = useNavigate();

    const orderedPaymentsByDueDate = [...container.containerPayments].sort((a, b) => {
        const aSeconds = new Date(a.dueDate).getTime();
        const bSeconds = new Date(b.dueDate).getTime();
        return aSeconds - bSeconds; // No need to add +1 here
    });


    const containerProduct = container.containerProducts[0];

    const intervals = Array(lenghtPrices).fill(0).map((_, index) => {
        const from = container.containerProducts[0].minQuantity + (index * container.containerProducts[0].interval);

        let to = container.containerProducts[0].minQuantity + ((index + 1) * container.containerProducts[0].interval) - 1;

        if (to > container.containerProducts[0].maxQuantity) {
            to = container.containerProducts[0].maxQuantity;
        }

        const price = Math.round(container.containerProducts[0].unitBasePrice - (index * container.containerProducts[0].unitBasePrice * container.containerProducts[0].increasedPercent));

        return {
            from: from,
            to: to,
            price: price
        } as PriceInterval;
    });

    const [totalPrice, setTotalPrice] = React.useState<number>(0);
    const [unitsSelected, setUnitsSelected] = React.useState<number>(0);

    useEffect(() => {
        setUnitsSelected(containerProduct.minQuantity);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const interval = intervals.find(interval => interval.from <= unitsSelected && interval.to >= unitsSelected);
        if (interval) {
            setTotalPrice(interval.price * unitsSelected);
        }
        // eslint-disable-next-line
    }, [unitsSelected]);

    return (
        <Card
            sx={{
                padding: '30px',
                borderRadius: '50px',
                borderColor: '#0A66A5',
                borderWidth: '1px',
                borderStyle: 'solid',
                color: '#0A66A5',
                height: '160px',
            }}
        >
            <Grid container direction={'column'} rowSpacing={2}>
                <Grid item xs>
                    <Typography
                        variant='body1'
                        textAlign={'center'}
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px'
                        }}
                    >
                        Importación
                    </Typography>
                </Grid>
                <Grid container item columnSpacing={2}>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Grid item>
                            <Typography
                                variant='body1'
                                textAlign={'center'}
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 600
                                }}
                            >
                                Cantidad
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'start'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    {Math.ceil(unitsSelected / containerProduct.unitsPerPackage)}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'end'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    paquetes
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'start'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    {unitsSelected}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'end'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    unidades
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Typography
                            variant='body1'
                            textAlign={'center'}
                            sx={{
                                fontSize: '12px',
                                fontWeight: 600
                            }}
                        >
                            Pagos
                        </Typography>
                        {orderedPaymentsByDueDate.map((payment, index) => {
                            return (
                                <Grid container key={index}>
                                    <Grid item xs>
                                        <Typography
                                            variant='body1'
                                            textAlign={'start'}
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {payment.percentage * 100}%
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant='body1'
                                            textAlign={'end'}
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {CLPFormatter.format(
                                                Math.round(totalPrice * payment.percentage)
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>)
                        })}
                    </Grid>
                </Grid>
                <Grid container item columnSpacing={2}>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Grid item xs>
                            <NumberInput
                                min={containerProduct.minQuantity}
                                step={containerProduct.unitsPerPackage}
                                max={container.units_total - container.units_sold}
                                value={unitsSelected}
                                onChange={(_, value) =>
                                    setUnitsSelected(value)
                                }
                            />
                        </Grid>
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 10,
                                height: 20,
                                backgroundColor: '#0A66A5',
                                color: 'white',
                                fontSize: '12px',
                                marginTop: '10px'
                            }}
                        >
                            Simular
                        </Button>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Grid container item xs>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'start'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='body1'
                                    textAlign={'end'}
                                    sx={{
                                        fontSize: '12px'
                                    }}
                                >
                                    {CLPFormatter.format(totalPrice)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 10,
                                height: 20,
                                backgroundColor: '#0A66A5',
                                color: 'white',
                                fontSize: '12px',
                                marginTop: '10px'
                            }}
                            onClick={() => {
                                navigate('/cart', { state: { container: container, totalPrice: totalPrice, units: unitsSelected } });
                            }}
                        >
                            Ir al carro
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing={2}>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Grid
                            item
                        >
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={6}
                        direction={'column'}
                    >
                        <Grid container>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    );
};

export default ContainerQuantityTable;
