import { combineReducers } from "redux";
import userSlice from "./slices/user_slice";
import layoutSlice from "./slices/layout_slice";
import containerSlice from "./slices/container_slice";
import registerSlice from "./slices/register_slice";
import productSlice from "./slices/product_slice";
import proformaSlice from "./slices/proforma_slice"; 
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  user: userSlice,
  layout: layoutSlice,
  container: containerSlice,
  register: registerSlice,
  product: productSlice,
  proformas: proformaSlice, 
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
