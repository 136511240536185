import GetContainersFullDetailUsecase from "../../domain/usecases/get_containers_full_detail_usecase";
import { setContainers } from "../redux/slices/container_slice";
import apiRepository from "../../data/repository";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";

export const GetContainersEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetContainersFullDetailUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => null,
        (data) => {
            dispatch(setContainers(data));
            dispatch(setStatus(StatusLayoutEnum.Success));
        }
    );
}