import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";


export interface LoginParams {
    email: string;
    password: string;
}

export default class LoginUserUsecase implements Usecase<string, LoginParams> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(params: LoginParams): Promise<Either<string>> {
        const token = await this.repository.loginUser(
            params.email,
            params.password
        );

        if (token.isRight()) {
            localStorage.setItem('token', token.right!);
        }

        return token;
    }
};