import { HttpClientType } from "../../core/http_client";
import {
  ContainerEntity, mapJsonToContainerEntity,
  UserEntity, mapJsonToUserEntity,
  ProductEntity, mapJsonToProductEntity,
  ContainerFullDetailEntity, mapJsonToContainerFullDetailEntity,
  ProformaEntity,
  mapJsonToProformaEntity,
  TransactionEntity, mapJsonToTransactionEntity,
} from "../../domain/entities";

export interface ILoginUser {
  email: string;
  password: string;
}

export interface ICreateTransaction {
  id: string;
  file: Blob;
  payment_id: string;
}

export interface ICreateProforma {
  id: string;
  product_container_id: string;
  quantity: number;
  price: number;
}

export interface IRegisterUser {
  user_data: {
    first_name: string;
    last_name: string;
    email: string;
  }
  company_data: {
    phone: string;
    business_rut: string;
    business_name: string;
    business_category: string;
  }
}

export interface ApiDataSource {

  // Containers
  getContainers(): Promise<ContainerEntity[]>;
  getContainersFullDetail(): Promise<ContainerFullDetailEntity[]>;

  loginUser(params: ILoginUser): Promise<string>;
  getProfile(): Promise<UserEntity>;
  registerUser(params: IRegisterUser): Promise<UserEntity>;

  // Products
  getProducts(): Promise<ProductEntity[]>;

  // Proforma
  getProformas(): Promise<ProformaEntity[]>;

  createTransaction(params: ICreateTransaction): Promise<TransactionEntity>;
  createProforma(params: ICreateProforma): Promise<ProformaEntity>;

  acceptUser(params: { user_id: string }): Promise<void>;

}

export class ApiDataSourceImpl implements ApiDataSource {
  httpClient: HttpClientType;

  constructor(httpClient: HttpClientType) {
    this.httpClient = httpClient;
  }

  getContainers = async (): Promise<ContainerEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/`,
    );
    return response.data.map(
      (container) => (mapJsonToContainerEntity(container)),
    );
  }

  getContainersFullDetail = async (): Promise<ContainerFullDetailEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/full-detail/`,
    );

    return response.data.map(
      (container) => (mapJsonToContainerFullDetailEntity(container)),
    );
  }

  getProducts = async (): Promise<ProductEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/products/`,
    );
    return response.data.map(
      (product) => (mapJsonToProductEntity(product)),
    );
  }

  loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<string> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/login/`,
      {
        "email": email,
        "password": password
      },
      false,
    );

    return response.data['token'] as string;
  }

  getProfile = async (): Promise<UserEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/profile/`,
    )
    return mapJsonToUserEntity(response.data);
  }

  registerUser = async (params: IRegisterUser): Promise<UserEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/register/`,
      params,
    );
    return mapJsonToUserEntity(response.data);
  }

  getProformas = async (): Promise<ProformaEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`,
    );
    return response.data.map(
      (proforma) => (mapJsonToProformaEntity(proforma)),
    );
  }

  createTransaction = async (params: ICreateTransaction): Promise<TransactionEntity> => {
    const formData = new FormData();

    formData.append('file', params.file);
    formData.append('payment', params.payment_id);
    formData.append('id', params.id);

    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/transactions/`,
      formData,
    );

    return mapJsonToTransactionEntity(response.data);
  }

  createProforma = async (params: ICreateProforma): Promise<ProformaEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`,
      {
        "id": params.id,
        "product_container": params.product_container_id,
        "quantity": params.quantity,
        "price": params.price,
      },
    );

    return mapJsonToProformaEntity(response.data);
  }

  acceptUser = async (params: { user_id: string }): Promise<void> => {
    await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/accept-user/${params.user_id}/`,
      {},
    );
  }
}
