export interface UserEntity {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
}

export function mapJsonToUserEntity(json): UserEntity {
    return {
        id: json.id,
        email: json.email,
        first_name: json.first_name,
        last_name: json.last_name,
    } as UserEntity;
}