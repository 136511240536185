import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import AppImages from '../../theme/app_images';

const OperationSection: React.FC = () => {
  return (
    <Box sx={{ paddingY: 8, backgroundColor: '#FFFFF' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          sx={{
            fontWeight: 600,
            fontSize: 64,
            lineHeight: '64px',
            textAlign: 'left',
            color: '#0A66A5',
            marginBottom: 2,
          }}
        >
          Nuestra operación
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '24.2px',
            textAlign: 'left',
            color: '#0A66A5',
            marginBottom: 4,
          }}
        >
          Nos ocupamos de todo el proceso, desde agrupar demanda hasta la internación de los productos en nuestro país.
        </Typography>
        <Box
          component="img"
          src={AppImages.operacion}
          alt="Nuestra operación"
          sx={{
            width: '100%',
            height: 'auto',
          }}
        />
      </Container>
    </Box>
  );
};
export default OperationSection;