import React, { Fragment } from "react";
import OperationSection from "../components/operation_section";
import LandingSection from "../components/landing_section";
import CarouselSection from '../components/carousel';
import RegisterHome from '../components/register_landing_form';
import { useAppSelector } from "../hooks/hooks";
import { StatusUserEnum } from "../redux/slices/user_slice";

const HomePage: React.FC = () => {
    const { status } = useAppSelector((state) => state.user);

    return (
        <Fragment>
            <LandingSection />
            <OperationSection />
            <CarouselSection />
            {
                status === StatusUserEnum.Success && <RegisterHome />
            }
        </Fragment>
    );
};

export default HomePage;