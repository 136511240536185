import { Either } from "./either";
import Usecase from "./usecase";
import { Failure } from "./failure";
import { ApiNetwork } from "./api_network";
import { HttpClient } from "./http_client";

const apiClient = new ApiNetwork();
const httpClient = new HttpClient();

export {
    apiClient,
    httpClient,
    Either,
    Usecase,
    Failure,
};