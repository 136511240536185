export interface PaymentEntityJson {
    id: string;
    amount: number;
    status: number;
    created_at: string;
    updated_at: string;
  }
  
  export interface PaymentEntity {
    id: string;
    amount: number;
    status: number;
    createdAt: string;
    updatedAt: string;
  }
  
  export function mapJsonToPaymentEntity(json: PaymentEntityJson): PaymentEntity {
    return {
      id: json.id,
      amount: json.amount,
      status: json.status,
      createdAt: new Date(json.created_at).toISOString(),
      updatedAt: new Date(json.created_at).toISOString(),
    };
  }
  