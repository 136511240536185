import { StatusLayoutEnum, setMessage, setStatus } from "../redux/slices/layout_slice";
import apiRepository from "../../data/repository";
import { setUser } from "../redux/slices/user_slice";
import GetProfileUsecase from "../../domain/usecases/get_profile_usecase";

export const GetProfileEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetProfileUsecase(apiRepository);


    const result = await usecase.execute();

    result.fold(
        (error) => {
            dispatch(setMessage(error.message));
            dispatch(setStatus(StatusLayoutEnum.Error));
        },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            dispatch(setUser(data));
        }
    );
}