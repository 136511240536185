import { Button } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

interface ButtonHeaderProps {
    title: string;
    route: string;
}

export const ButtonHeader: FC<ButtonHeaderProps> = ({
    title, route
}): ReactElement => {

    const navigate = useNavigate();

    return (
        <Button
            onClick={() => navigate(route)}
            sx={{
                color: 'white',
                margin: '10px',
                fontSize: '1.2em',
            }}
        >
            {title}
        </Button>
    );
}
