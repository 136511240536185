import { Either } from "./either";
import { Failure } from "./failure";

export class ApiNetwork {
    async call<R>(
        func: () => Promise<R>,
    ): Promise<Either<R>> {
        try {
            const data = await func();
            return Either.right(data);
        } catch (error) {
            console.error(error);
            const failure = new Failure({ message: error.message || "An error occurred", statusCode: error.statusCode || 404 });
            return Either.left(failure);
        }
    }
}

export type ApiNetworkType = ApiNetwork;