import React, { Fragment, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { GetProfileEvent } from '../../events/get_profile_event';

const SessionBackdrop: React.FC = () => {
    const { user } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(GetProfileEvent());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user !== null) {
            enqueueSnackbar('Bienvenido', { variant: 'success', autoHideDuration: 2000 });
        } else {
            enqueueSnackbar('Sesión cerrada', {
                variant: 'success', autoHideDuration: 2000,
            });
        }
        navigate('/');
        //eslint-disable-next-line
    }, [user]);

    return (
        <Fragment></Fragment>
    );
}

export default SessionBackdrop;