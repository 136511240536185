import { UserEntity } from '../entities/user/user_entity';
import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';

interface RegisterParams {
    user_data: {
        first_name: string;
        last_name: string;
        email: string;
    };
    company_data: {
        phone: string;
        business_rut: string;
        business_name: string;
        business_category: string;
    };
}

export default class RegisterUserUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }

    async execute(params: RegisterParams): Promise<Either<UserEntity>> {
        return await this.repository.registerUser(params.user_data, params.company_data);
    }
}




