import React from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { ProgressStepEntity } from '../../domain/entities/progress_step';

interface StepProgressProps {
  steps: ProgressStepEntity[];
}

const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper alternativeLabel>
        {steps.map((step: ProgressStepEntity, index) => (
          <Step key={index} completed={step.is_completed}>
            <StepLabel>{step.status.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepProgress;