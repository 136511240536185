
export enum ContainerStatusEnum {
    INITIALIZED = 0,
    ACTIVE = 1,
    INACTIVE = 2

    // Añade otros estados posibles aquí si es necesario
}

export interface MetricUnitEntity {
    // Define las propiedades de MetricUnit aquí
    name: string; // Corresponde a models.CharField
    description: string; // Corresponde a models.TextField
    square_meters: number; // Corresponde a models.FloatField
    // createdAt: Date; // Corresponde a models.DateTimeField(auto_now_add=True)
    // updatedAt: Date; // Corresponde a models.DateTimeField(auto_now=True)

}

export function mapJsonToMetricUnit(json): MetricUnitEntity {
    return {
        name: json.name,
        description: json.description,
        square_meters: json.square_meters,
    } as MetricUnitEntity;
}

export interface ContainerEntity {
    id: string; // Corresponde a models.CharField
    capacity: number; // Corresponde a models.FloatField
    fixedCost: number; // Corresponde a models.FloatField
    tax: number; // Corresponde a models.FloatField
    status: ContainerStatusEnum; // Corresponde a models.IntegerField con choices
    metricUnitId: number; // Corresponde a models.ForeignKey
    createdAt: string; // Corresponde a models.DateTimeField(auto_now_add=True)
    updatedAt: string; // Corresponde a models.DateTimeField(auto_now=True)
}

export function mapJsonToContainerEntity(json): ContainerEntity {
    return {
        id: json.id,
        capacity: json.capacity,
        fixedCost: json.fixed_cost,
        tax: json.tax,
        status: json.status,
        metricUnitId: json.metric_unit,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as ContainerEntity;
}
