import { Either, Usecase } from "../../core/index";
import { ApiRepositoryImpl } from "../../data/repository/api";
import { ProformaEntity } from "../entities/proforma/proforma_entity";

export default class GetProformasUsecase implements Usecase<ProformaEntity[]> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<ProformaEntity[]>> {
        const data = await this.repository.getProformas();
        return data;
    }
}
