import * as React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppSelector } from '../../hooks/hooks';
import { StatusLayoutEnum } from '../../redux/slices/layout_slice';

const LoadingBackdrop: React.FC = () => {
    const { status } = useAppSelector(state => state.layout);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={status === StatusLayoutEnum.Loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default LoadingBackdrop;