import LogoutUserUsecase from "../../domain/usecases/logout_user_usecase";
import { setUser } from "../redux/slices/user_slice";

export const LogoutEvent = () => async (dispatch) => {
    const usecase = new LogoutUserUsecase();
    const result = await usecase.execute();
    result.fold(
        () => null,
        () => {
            dispatch(setUser(null))
        }
    );
}