import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { TransactionEntity } from '../entities';

interface CreateTransactionParams {
    id: string;
    file: Blob;
    payment_id: string;
}

export default class CreateTransactionUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }

    async execute(params: CreateTransactionParams): Promise<Either<TransactionEntity>> {
        return this.repository.createTransaction(
            params.id,
            params.file,
            params.payment_id
        );
    }
}
