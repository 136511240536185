import React from 'react';
import { Container, Typography, Box, Card, CardContent, Button, Input, LinearProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AppImages from '../../theme/app_images';


const EstadoDeCuenta: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Ejemplos mientras estoy manejando el back 
  const proforma = {
    id: 'proforma-001',
    productName: 'Paño amarillo multiuso',
    productImageUrl: AppImages.panoAmarillo,
    totalAmount: 7800000,
    payments: [
      {
        amount: 3900000,
        dueDate: '27 de Julio de 2024',
        uploadDate: '',
        receptionDate: '',
        transactionType: '',
        currency: 'CLP',
        equivalence: '',
      },
      {
        amount: 0,
        dueDate: '27 de Agosto de 2024',
        uploadDate: '',
        receptionDate: '',
        transactionType: '',
        currency: 'CLP',
        equivalence: '',
      }
    ]
  };

  const totalTransferred = proforma.payments.reduce((sum, payment) => sum + payment.amount, 0);
  const totalAmount = proforma.totalAmount;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Aquí voy a recibir el archivo
      console.log(file);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Estado de cuenta
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <Box sx={{ flex: 1 }}>
          <img src={proforma.productImageUrl} alt="Producto" style={{ maxWidth: '100%', height: 'auto' }} />
          <Card
            sx={{
              padding: '5px',
              borderRadius: '30px',
              borderColor: '#0A66A5',
              borderWidth: '1px',
              borderStyle: 'solid',
              mt: 2,
            }}
          >
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  40x40
                </Typography>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  Centímetros
                </Typography>
              </Grid>
              <div style={{ border: '1px solid #0A66A5' }}></div>
              <Grid item xs sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  40
                </Typography>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  Gramos
                </Typography>
              </Grid>
              <div style={{ border: '1px solid #0A66A5' }}></div>
              <Grid item xs sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  15% algodón
                </Typography>
                <Typography variant='body2' sx={{
                  color: '#0A66A5',
                  lineHeight: '1'
                }}>
                  80% poliester
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" component="div">
              Total transferido
            </Typography>
            <Typography variant="h4" component="div" sx={{ color: 'primary.main' }}>
              ${totalTransferred.toLocaleString('es-CL')} <Typography variant="h4" component="span" sx={{ color: 'text.secondary' }}>/ ${totalAmount.toLocaleString('es-CL')}</Typography>
            </Typography>
            <LinearProgress variant="determinate" value={(totalTransferred / totalAmount) * 100} sx={{ mt: 1 }} />
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
              {((totalTransferred / totalAmount) * 100).toFixed(2)}% abonado
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 2, pl: 2 }}>
          {proforma.payments.map((payment, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {`Pago ${index + 1}`}
                </Typography>
                <Typography variant="body1">Fecha de vencimiento estimada: {payment.dueDate}</Typography>
                <Typography variant="body1">Fecha de subida documento: {payment.uploadDate}</Typography>
                <Typography variant="body1">Fecha de recepción conforme: {payment.receptionDate}</Typography>
                <Typography variant="body1">Tipo de transacción: {payment.transactionType}</Typography>
                <Typography variant="body1">Moneda: {payment.currency}</Typography>
                <Typography variant="body1">Monto total: {payment.amount.toLocaleString('es-CL')}</Typography>
                <Typography variant="body1">Equivalencia: {payment.equivalence}</Typography>
                <label htmlFor={`upload-file-${index}`}>
                  <Input
                    id={`upload-file-${index}`}
                    type="file"
                    sx={{ display: 'none' }}
                    inputProps={{ accept: "*/*" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{ mt: 2 }}
                  >
                    Subir comprobante
                  </Button>
                </label>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default EstadoDeCuenta;
