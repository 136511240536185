import apiRepository from "../../data/repository";
import AcceptUserUsecase from "../../domain/usecases/accept_user_usecase";

export const AcceptUserEvent = async (
    user_id: string,
) => {
    const usecase = new AcceptUserUsecase(apiRepository);

    await usecase.execute({
        user_id: user_id,
    });

}