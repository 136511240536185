import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { ContainerFullDetailEntity } from "../entities";

export default class GetContainersFullDetailUsecase implements Usecase<ContainerFullDetailEntity[]> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<ContainerFullDetailEntity[]>> {
        const data = await this.repository.getContainersFullDetail();

        return data;
    }
};