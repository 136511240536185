import GetProformasUsecase from '../../domain/usecases/get_proformas_usecase';
import { setProformas } from '../redux/slices/proforma_slice';
import apiRepository from '../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../redux/slices/layout_slice';

export const GetProformasEvent = () => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetProformasUsecase(apiRepository);

  const result = await usecase.execute();

  result.fold(
    (error) => {
      console.error('Error fetching proformas:', error);
    },
    (data) => {
      console.log('Proformas fetched:', data);
      dispatch(setProformas(data));
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
};
