import axios, { AxiosInstance, AxiosResponse } from "axios";

export class HttpClient {
    _getAxiosInstance(auth: boolean): AxiosInstance {
        const axiosInstance = axios.create({
            baseURL: 'http://localhost:7009/api/',
        });
        if (auth) {
            const token = localStorage.getItem('token');
            axiosInstance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            });
        }

        return axiosInstance;
    }

    get = async (url: string, auth = true): Promise<AxiosResponse> => {
        const axiosInstance = this._getAxiosInstance(auth);
        return axiosInstance.get(url);
    }

    post = async (url: string, data: unknown, auth = true): Promise<AxiosResponse> => {
        const axiosInstance = this._getAxiosInstance(auth);
        return axiosInstance.post(url, data);
    }

    put = async (url: string, data: unknown, auth = true): Promise<AxiosResponse> => {
        const axiosInstance = this._getAxiosInstance(auth);
        return axiosInstance.put(url, data);
    }

    delete = async (url: string, auth = true): Promise<AxiosResponse> => {
        const axiosInstance = this._getAxiosInstance(auth);
        return axiosInstance.delete(url);
    }
}

export type HttpClientType = HttpClient;