import { Failure } from "./failure";


export class Either<R> {
    left: Failure | null;
    right: R | null;

    constructor(left: Failure | null, right: R | null) {
        this.left = left;
        this.right = right;
    }

    static left<R>(value: Failure): Either<R> {
        return new Either<R>(value, null);
    }

    static right<R>(value: R): Either<R> {
        return new Either<R>(null, value);
    }

    fold(leftFn: (value: Failure) => void, rightFn: (value: R) => void): void {
        if (this.left !== null) {
            return leftFn(this.left);
        } else if (this.right !== null) {
            return rightFn(this.right);
        }
    }

    isLeft(): boolean {
        return this.left !== null;
    }

    isRight(): boolean {
        return this.right !== null;
    }
}