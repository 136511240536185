import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export enum StatusLayoutEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export interface LayoutState {
    status: StatusLayoutEnum;
    message: string | null;
}

const initialState = {
    status: StatusLayoutEnum.Initial,
    message: null,
} as LayoutState;

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setStatus(state, action: PayloadAction<StatusLayoutEnum>) {
            state.status = action.payload
            state.message = null
        },
        setMessage(state, action: PayloadAction<string | null>) {
            state.message = action.payload
        },
    },
})

export const { setStatus, setMessage } = layoutSlice.actions
export default layoutSlice.reducer