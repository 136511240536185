import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";

export interface AcceptUserParams {
    user_id: string;
}

export default class AcceptUserUsecase implements Usecase<void, AcceptUserParams> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(params: AcceptUserParams): Promise<Either<void>> {
        return this.repository.acceptUser(
            params.user_id
        );
    }
};