import { Either } from "../../core";

import { ApiNetworkType } from "../../core/api_network";
import { ApiRepository } from "../../domain/repository/api";
import { ApiDataSource } from "../datasource/api";

import { ProductEntity, ContainerEntity, UserEntity, ContainerFullDetailEntity, ProformaEntity, TransactionEntity } from "../../domain/entities";

export class ApiRepositoryImpl implements ApiRepository {
    datasource: ApiDataSource;
    api: ApiNetworkType;

    constructor(datasource: ApiDataSource, api: ApiNetworkType) {
        this.datasource = datasource;
        this.api = api;
    }

    getContainers = async (): Promise<Either<ContainerEntity[]>> => {
        const result = await this.api.call<ContainerEntity[]>(this.datasource.getContainers);
        return result;
    }
    getContainersFullDetail = async (): Promise<Either<ContainerFullDetailEntity[]>> => {
        const result = await this.api.call<ContainerFullDetailEntity[]>(
            this.datasource.getContainersFullDetail,
        );
        return result;
    }

    getProducts = async (): Promise<Either<ProductEntity[]>> => {
        const result = await this.api.call<ProductEntity[]>(this.datasource.getProducts);
        return result;
    }

    loginUser = async (email: string, password: string): Promise<Either<string>> => {
        const result = await this.api.call<string>(
            () => this.datasource.loginUser(
                { email: email, password: password }
            ),
        );
        return result;
    }

    getProfile = async (): Promise<Either<UserEntity>> => {
        const result = await this.api.call<UserEntity>(
            this.datasource.getProfile
        );
        return result;
    }

    registerUser = async (user_data: { first_name: string, last_name: string, email: string }, company_data: { phone: string, business_rut: string, business_name: string, business_category: string }): Promise<Either<UserEntity>> => {
        const result = await this.api.call<UserEntity>(
            () => this.datasource.registerUser(
                { user_data, company_data }
            ),
        );
        return result;
    }

    getProformas = async (): Promise<Either<ProformaEntity[]>> => {
        const result = await this.api.call<ProformaEntity[]>(this.datasource.getProformas);
        return result;
    }

    createTransaction = async (id: string, file: Blob, payment_id: string): Promise<Either<TransactionEntity>> => {
        const result = await this.api.call<TransactionEntity>(
            () => this.datasource.createTransaction(
                { id, file, payment_id }
            ),
        );
        return result;
    }

    createProforma = async (id: string, product_container_id: string, quantity: number, price: number): Promise<Either<ProformaEntity>> => {
        const result = await this.api.call<ProformaEntity>(
            () => this.datasource.createProforma(
                { id, product_container_id, quantity, price }
            ),
        );
        return result;
    }

    acceptUser = async (user_id: string): Promise<Either<void>> => {
        const result = await this.api.call<void>(
            () => this.datasource.acceptUser(
                { user_id }
            ),
        );
        return result;
    }
}
