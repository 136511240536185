import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ContainerFullDetailEntity } from '../../../domain/entities/container/index'

export interface ContainerState {
    containers: ContainerFullDetailEntity[] | [];
    status: StatusContianerEnum;
}

export enum StatusContianerEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

const initialState = {
    containers: [],
    status: StatusContianerEnum.Initial,
} as ContainerState;

const containerSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setContainers(state, action: PayloadAction<ContainerFullDetailEntity[] | []>) {
            state.containers = action.payload
        },
        setStatusContainer(state, action: PayloadAction<StatusContianerEnum>) {
            state.status = action.payload
        },
        reset(state) {
            state.containers = []
            state.status = StatusContianerEnum.Initial
        }
    },
})

export const {
    setContainers,
    setStatusContainer,
    reset
} = containerSlice.actions
export default containerSlice.reducer
