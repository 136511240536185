// src/components/ContainerList.tsx
import React, { Fragment } from 'react';
import { ContainerFullDetailEntity } from '../../domain/entities';
import { Card, Grid, Typography } from '@mui/material';

interface ContainerPriceTableProps {
    container: ContainerFullDetailEntity;
    lenghtPrices: number;
}

const ContainerPriceTable: React.FC<ContainerPriceTableProps> = ({ container, lenghtPrices }) => {

    console.log(container);
    console.log(lenghtPrices);

    const validLengthPrices = Math.max(0, Math.floor(lenghtPrices || 0));


    return (
        <Card
            sx={{
                padding: '30px',
                marginBottom: '40px',
                borderRadius: '50px',
                backgroundColor: '#0A66A5',
                height: '160px',
                color: 'white'
            }}
        >
            <Grid container direction={'column'} rowSpacing={2}>
                <Grid item xs>
                    <Typography
                        variant='body1'
                        textAlign={'center'}
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px'
                        }}
                    >
                        Tabla de precios
                    </Typography>
                </Grid>
                <Grid container item>
                    <Grid item xs={4}>
                        <Typography
                            variant='body1'
                            textAlign={'center'}
                            sx={{
                                fontSize: '12px',
                                fontWeight: 600
                            }}
                        >
                            Desde
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant='body1'
                            textAlign={'center'}
                            sx={{
                                fontSize: '12px',
                                fontWeight: 600
                            }}
                        >
                            Hasta
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant='body1'
                            textAlign={'center'}
                            sx={{
                                fontSize: '12px',
                                fontWeight: 600
                            }}
                        >
                            Precio (IVA inc)
                        </Typography>
                    </Grid>
                    {

                        Array(validLengthPrices).fill(0).map((_, index) => {
                            console.log('Entre al array ...');

                            const from = container.containerProducts[0].minQuantity + (index * container.containerProducts[0].interval);

                            let to = container.containerProducts[0].minQuantity + ((index + 1) * container.containerProducts[0].interval) - 1;

                            if (to > container.containerProducts[0].maxQuantity) {
                                to = container.containerProducts[0].maxQuantity;
                            }

                            const price = Math.round(container.containerProducts[0].unitBasePrice - (index * container.containerProducts[0].unitBasePrice * container.containerProducts[0].increasedPercent));
                            return (
                                <Fragment key={index}>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant='body1'
                                            textAlign={'center'}
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {from}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant='body1'
                                            textAlign={'center'}
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {to}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant='body1'
                                            textAlign={'center'}
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {price}
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Card>
    );
};

export default ContainerPriceTable;
