import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProformaEntity } from '../../../domain/entities/proforma/proforma_entity';

export interface ProformaState {
  proformas: ProformaEntity[];
}

const initialState: ProformaState = {
  proformas: [],
};

const proformaSlice = createSlice({
  name: 'proformas',
  initialState,
  reducers: {
    setProformas(state, action: PayloadAction<ProformaEntity[]>) {
      state.proformas = action.payload;
    },
  },
});

export const { setProformas } = proformaSlice.actions;
export default proformaSlice.reducer;
