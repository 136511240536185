import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import AppImages from '../../theme/app_images';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { RegisterStatusEnum } from '../redux/slices/register_slice';
import { registerUserEvent } from '../events/get_register_event';
import { useNavigate } from 'react-router-dom';

const RegisterHome = () => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [business_rut, setBusinessRut] = useState('');
  const [business_name, setBusinessName] = useState('');
  const [business_category, setBusinessCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { status } = useAppSelector((state) => state.register);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validateChileanRUT = (rut: string): boolean => {
    const cleanRut: string = rut.replace(/[^0-9kK]/g, '').toUpperCase();
    const rutBody: string = cleanRut.slice(0, -1);
    const dv: string = cleanRut.slice(-1);

    if (rutBody.length < 7) return false;

    let total: number = 0;
    let factor: number = 2;

    for (let i = rutBody.length - 1; i >= 0; i--) {
      total += parseInt(rutBody[i], 10) * factor;
      factor = factor === 7 ? 2 : factor + 1;
    }

    let expectedDV: string | number = 11 - (total % 11);
    if (expectedDV === 11) expectedDV = '0';
    else if (expectedDV === 10) expectedDV = 'K';
    else expectedDV = expectedDV.toString();

    return expectedDV === dv;
  };


  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone: string) => {
    const re = /^[0-9]{8,15}$/;
    return re.test(phone);
  };

  const validateRUT = (rut: string) => {
    return rut.length > 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!first_name || !last_name) {
      setError('Nombre y Apellido son requeridos.');
      setLoading(false);
      return;
    }

    if (!validateChileanRUT(business_rut)) {
      setError('Por favor, ingresa un RUT válido.');
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError('Por favor, ingresa un correo electrónico válido.');
      setLoading(false);
      return;
    }

    if (!validatePhone(phone)) {
      setError('Por favor, ingresa un número de teléfono válido.');
      setLoading(false);
      return;
    }

    if (!validateRUT(business_rut)) {
      setError('Por favor, ingresa un RUT válido.');
      setLoading(false);
      return;
    }

    if (!business_name || !business_category) {
      setError('Nombre de la Empresa y Categoría son requeridos.');
      setLoading(false);
      return;
    }

    // Aquí mandamos a back!

    setLoading(false);

    dispatch(registerUserEvent(
      first_name,
      last_name,
      email,
      phone,
      business_rut,
      business_name,
      business_category,
    ));

  };

  useEffect(() => {
    if (status === RegisterStatusEnum.Success) {
      navigate('/success-register');
    }
  }, [status]);


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${AppImages.backhomeregister})`,
        backgroundSize: 'cover',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          width: '100%',
          backgroundColor: '#0A66A5',
          padding: '40px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginLeft: '10%',
          color: 'white',
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom textAlign="center">
          ¡Regístrate en el formulario!
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Nombre"
            name="first_name"
            autoComplete="fname"
            autoFocus
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Apellido"
            name="last_name"
            autoComplete="lname"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Teléfono"
            type="tel"
            id="phone"
            autoComplete="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="business_rut"
            label="RUT Empresa"
            id="business_rut"
            autoComplete="business-rut"
            value={business_rut}
            onChange={(e) => setBusinessRut(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="business_name"
            label="Nombre Empresa"
            id="business_name"
            autoComplete="business-name"
            value={business_name}
            onChange={(e) => setBusinessName(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="business_category"
            label="Categoría Empresa"
            id="business_category"
            autoComplete="business-category"
            value={business_category}
            onChange={(e) => setBusinessCategory(e.target.value)}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiInputBase-input': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'lightgray' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {status === RegisterStatusEnum.Loading ?
              <CircularProgress size={40} sx={{ mt: 3 }} /> :
              <Button
                type="submit"
                style={{
                  width: '50%',
                  borderRadius: '10px',
                  height: '40px',
                  backgroundColor: 'white',
                  color: '#0A66A5',
                  fontSize: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                Enviar
              </Button>
            }
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterHome;
