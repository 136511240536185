import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum RegisterStatusEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export interface RegisterState {
    status: RegisterStatusEnum;
    error: string | null;
}

const initialState = {
    status: RegisterStatusEnum.Initial,
    error: null,
} as RegisterState;

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        registerStart(state) {
            state.status = RegisterStatusEnum.Loading;
            state.error = null;
        },
        registerSuccess(state) {
            state.status = RegisterStatusEnum.Success;
            state.error = null;
        },
        registerFailure(state, action: PayloadAction<string>) {
            state.status = RegisterStatusEnum.Error;
            state.error = action.payload;
        },
    },
});

export const { registerStart, registerSuccess, registerFailure } = registerSlice.actions;
export default registerSlice.reducer;
