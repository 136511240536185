export default class AppImages {
    static loginImage = 'https://contandem-assets-public.s3.amazonaws.com/assets/Web-login.jpg';
    static barcoLanding = 'https://contandem-assets-public.s3.amazonaws.com/assets/barco-landing.png';
    static registerImage = 'https://contandem-assets-public.s3.amazonaws.com/assets/Web-register.jpg';
    static logo = 'https://contandem-assets-public.s3.amazonaws.com/assets/logo.svg';
    static logowhite = 'https://contandem-assets-public.s3.amazonaws.com/assets/logo-transparente.png';
    static operacion = 'https://contandem-assets-public.s3.amazonaws.com/assets/operacion-v2.svg';
    static panoAmarillo = 'https://contandem-assets-public.s3.amazonaws.com/assets/pano_amarillo.svg';
    static sponsor = 'https://contandem-assets-public.s3.amazonaws.com/assets/sponsors.svg';
    static banner = 'https://contandem-assets-public.s3.amazonaws.com/assets/Banner2.jpg';
    static backhomeregister = 'https://contandem-assets-public.s3.amazonaws.com/assets/fondo-home-register.svg';
    static successRegister = 'https://contandem-assets-public.s3.amazonaws.com/assets/registro-success.svg';
}
