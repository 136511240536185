// src/components/ContainerList.tsx
import React, { Fragment } from 'react';
import { ProductEntity } from '../../domain/entities';
import { Card, Typography } from '@mui/material';

interface ProductCardProps {
    product: ProductEntity;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    return (
        <Fragment>
            <Card elevation={3} sx={{
                height: 200,
                aspectRatio: '1/1',
                backgroundSize: 'cover',
                backgroundImage: `url(${product.imageUrl})`,
                marginBottom: '10px',
                borderRadius: '8px'
            }}>
            </Card>
            <Typography variant='h6'>
                {product.name}
            </Typography>
            <Typography variant='body2'>
                {product.description}
            </Typography>
            <Typography variant='body2' sx={{ color: 'GrayText' }}>
                $ {product.price} USD
            </Typography>
        </Fragment>
    );
};

export default ProductCard;
