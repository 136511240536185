import React from "react";
import LoginForm from "../components/login_form";

const LoginPage: React.FC = () => {
    return (
        <div>
            <LoginForm />
        </div>
    );
}

export default LoginPage;