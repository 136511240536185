// src/presentation/components/carousel.tsx
import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const CarouselSection = () => {
  const items = [
    {
      testimonial: "Contandem nos ha ayudado a crecer y a reducir nuestros costos significativamente. ¡Recomendados!",
      author: "Elías"
    },
    {
      testimonial: "Excelente servicio y atención personalizada, siempre atentos a nuestras necesidades.",
      author: "Juan Carlos"
    },
    {
      testimonial: "Gracias a Contandem hemos optimizado nuestras operaciones de importación y reducido los tiempos de entrega.",
      author: "Evelyn"
    }
  ];

  return (
    <Box sx={{ py: 8, backgroundColor: '#F5F5F5' }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: '#0A66A5',
            borderRadius: '16px',
            padding: '40px', 
            textAlign: 'center',
            color: 'white',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Lo que dicen nuestros clientes
          </Typography>
          <Carousel
            indicators={true}  
            navButtonsAlwaysVisible={true}  
            animation="slide"  
            autoPlay={true} 
            interval={4000} 
          >
            {items.map((item, index) => (
              <Box key={index} sx={{ padding: '20px' }}>
                <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                  "{item.testimonial}"
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  - {item.author}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default CarouselSection;
