
import { setProducts } from "../redux/slices/product_slice";
import apiRepository from "../../data/repository";
import GetProductsUsecase from "../../domain/usecases/get_products_usecase";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";

export const GetProductsEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetProductsUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));;
            dispatch(setProducts(data));
        }
    );
}
